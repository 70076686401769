@use '@angular/material' as mat;
@import '@angular/material/theming';
// Always include only once per project
@include mat.core();
// Import our custom theme
@import 'sass/app-theme.scss';
@import 'sass/fonts.scss';
@import 'sass/cartus-palette.scss';
@import 'sass/app.scss';
@import 'sass/common-styles.scss';
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
@mixin custom-components-theme($theme, $typography, $cartus-palette) {
    @include app($theme, $typography, $cartus-palette);
}

@include mat.all-component-themes($app-theme);
.ccu-theme {
    // use our theme with angular-material-theme mixin
    // @include mat.all-component-themes($app-theme);
    // include custom components theme mixin once per theme class
    @include custom-components-theme($app-theme, $typography, $cartus-palette);
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    height: 100vh;
    min-width: 331px;
    overflow: visible !important;
    font-family: 'Avenir-Book', sans-serif;
    font-size: 16px;
    color: map-get($cartus-palette, 'cartus-gray');
}

*[fxlayoutgap]>*:last-child {
    margin-right: 0;
    /* Fix for flex last column in a row having a right margin */
}

// Cross Frame Scripting fix - comment out for testing unit tests when not using ChromeHeadless for unit testing 
html {
    display: none;
    visibility: hidden;
}
